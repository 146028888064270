var langFE = $('html').attr('lang');
var languageFE = {}
var languageSelect2 = {}
languageSelect2['vi'] = {
	errorLoading: function () { return "Không tìm thấy kết quả." },
	inputTooLong: function (n) { return "Vui lòng xóa bớt " + (n.input.length - n.maximum) + " ký tự" },
	inputTooShort: function (n) { return "Vui lòng nhập thêm từ " + (n.minimum - n.input.length) + " ký tự trở lên" },
	loadingMore: function () { return "Đang lấy thêm kết quả…" },
	maximumSelected: function (n) { return "Chỉ có thể chọn được " + n.maximum + " lựa chọn" },
	noResults: function () { return "Không tìm thấy kết quả" },
	searching: function () { return "Đang tìm…" },
	removeAllItems: function () { return "Xóa tất cả các mục" },
	removeItem: function () { return "Xoá" },
	search: function () { return "Tìm kiếm" }
}
languageSelect2['en'] = {
	errorLoading: function () { return "The results could not be loaded." },
	inputTooLong: function (e) { var n = e.input.length - e.maximum, r = "Please delete " + n + " character"; return 1 != n && (r += "s"), r },
	inputTooShort: function (e) { return "Please enter " + (e.minimum - e.input.length) + " or more characters" },
	loadingMore: function () { return "Loading more results…" },
	maximumSelected: function (e) { var n = "You can only select " + e.maximum + " item"; return 1 != e.maximum && (n += "s"), n },
	noResults: function () { return "No results found" },
	searching: function () { return "Searching…" },
	removeAllItems: function () { return "Remove all items" },
	removeItem: function () { return "Remove item" },
	search: function () { return "Search" }
}

languageFE['vi'] = {
	'error': 'Lỗi',
	'warning': 'Cảnh báo',
	'info': 'Thông báo',
	'success': 'Thành công',
	'confirm': 'Xác nhận',
	'are_you_sure': 'Bạn có chắc chắn?',
	'yes': 'Có',
	'no': 'Không',
	'cancel': 'Hủy',
	'close': 'Đóng',
	'ok': 'Đồng ý',
	'please_wait': 'Vui lòng đợi...',
	'please_try_again': 'Vui lòng thử lại',
	'one_lower_upper_num': 'Bao gồm số, chữ viết hoa, chữ viết thường',
	'one_lower': 'Ít nhất 1 ký tự viết thường',
	'one_upper': 'Ít nhất 1 ký tự viết hoa',
	'one_number': 'Ít nhất 1 ký tự số',
	'one_special': 'Ít nhất 1 ký tự đặc biệt !@#$%^&*()...',
	'at_least_8_characters': 'Ít nhất 8 - 15 ký tự',
}
languageFE['en'] = {
	'error': 'Error',
	'warning': 'Warning',
	'info': 'Info',
	'success': 'Success',
	'confirm': 'Confirm',
	'are_you_sure': 'Are you sure?',
	'yes': 'Yes',
	'no': 'No',
	'cancel': 'Cancel',
	'close': 'Close',
	'ok': 'OK',
	'please_wait': 'Please wait...',
	'please_try_again': 'Please try again',
	'one_lower_upper_num': 'At least lowercase, uppercase, number letter',
	'one_lower': 'At least 1 lowercase letter',
	'one_upper': 'At least 1 uppercase letter',
	'one_number': 'At least 1 number',
	'one_special': 'At least 1 special character !@#$%^&*()...',
	'at_least_8_characters': 'At least 8 - 15 characters',
}

$(function () {
	APP.init();
	lozadInit();
	fancyboxInit();
	accordionInit();
	revealPwd();
	inputPasswordValidate();
	// sliderInit(); // range slider - noUiSlider
	jobsFilterInit();
	onlicvMenuInit();
	toggleDescription();
	toggleFaqsCategories();

	countUpInit();

	PROFILE.init();
	EMPLOYER.init();

	// form
	uploadInit();
	// CKEditorInit(); // ckeditor
	filepondInit(); // filepond

});

$(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = $("header"),
	body = $("body"),
	backdrop = $(".backdrop"),
	backToTop = $(".back-to-top"),
	buttonMenu = $("#buttonMenu"),
	mobileWrap = $(".mobile-wrap"),
	buttonSearch = $("header .button-search"),
	searchWrap = $(".search-wrap"),
	heightHeader = $("header").height(),
	heightWindow = $(window).height(),
	widthWindow = $(window).width(),
	outerHeightWindow = $(window).outerHeight();

var APP = {
	fixed: () => {
		// header
		$(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		$(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			$(this).removeClass('open');
			buttonMenu.removeClass('open'); // remove button menu header
			mobileWrap.removeClass('open'); // remove mobile wrap
			$(".jobs-filter-bar").removeClass('open'); // remove jobs filter bar
			$(".job-sorting").removeClass('open'); // remove job sorting
			body.removeClass('overflow-hidden');
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			$('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	customSelect: () => {
		if ($(".custom-select-multiple").length > 0) {
			$('.custom-select-multiple select').each(function () {
				let dataPlaceholder = $(this).data('placeholder');
				let dataSelectAll = $(this).data('selectall');
				let dataMultiple = $(this).attr('multiple');
				let dataLimit = $(this).data('limit');
				let searchOption = $(this).attr('searchoption');
				let isSearch = typeof searchOption !== 'undefined' && searchOption !== false;
				let isSelectAll = typeof dataSelectAll !== 'undefined' && dataSelectAll !== false;
				let isMultiple = typeof dataMultiple !== 'undefined' && dataMultiple !== false;
				$(this).select2({
					tags: false,
					tokenSeparators: [',', ' '],
					closeOnSelect: !isMultiple,
					allowClear: true,
					theme: "classic",
					allowHtml: true,
					maximumSelectionLength: dataLimit,

					language: {
						...languageSelect2[langFE],
					},
					// minimumResultsForSearch: false,
					// minimumInputLength: 3, // only start searching when the user has input 3 or more characters
					// minimumResultsForSearch: Infinity,
					disableSearch: false,
					dropdownParent: $(document.body),
					// matcher: matchStart,
					// templateSelection: formatSelect
				});
				$(this).on("change", function (e) {
					// console.log(e);
				});
			});
		}
		if ($('.custom-select-single').length > 0) {
			$('.custom-select-single select').each(function () {
				let searchOption = $(this).attr('searchoption');
				$(this).select2({
					minimumResultsForSearch: Infinity,
				});
			});
		}
		function formatSelect(state, index) {
			if (!state.id) {
				return state.text;
			}
			var $state = $('<span><span></span></span>');
			$state.find("span").text(state.text);
			console.log(index.length);
			// if(state.id > 0){
			// 	$state.find('span').text('+ ' + state.id);
			// }
			return $state;
		}

		function matchStart(params, data) {
			// If there are no search terms, return all of the data
			if ($.trim(params.term) === '') {
				return data;
			}

			// Skip if there is no 'children' property
			if (typeof data.children === 'undefined') {
				return null;
			}

			// `data.children` contains the actual options that we are matching against
			var filteredChildren = [];
			$.each(data.children, function (idx, child) {
				if (child.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) {
					filteredChildren.push(child);
				}
			});

			// If we matched any of the timezone group's children, then set the matched children on the group
			// and return the group object
			if (filteredChildren.length) {
				var modifiedData = $.extend({}, data, true);
				modifiedData.children = filteredChildren;

				// You can return modified objects from here
				// This includes matching the `children` how you want in nested data sets
				return modifiedData;
			}

			// Return `null` if the term should not be displayed
			return null;
		}
	},
	mapping: () => {
		const mainMenu = $("header .navbar-nav").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "appendTo",
			desktopWrapper: "header .header-right",
			desktopMethod: "prependTo",
			breakpoint: 1279.98,
		});
		const buttonGroup = $("header .button-group").mapping({
			mobileWrapper: ".mobile-wrap .navbar-nav-list",
			mobileMethod: "insertAfter",
			desktopWrapper: "header .header-right .button-language",
			desktopMethod: "insertBefore",
			breakpoint: 1023.98,
		});
	},
	toggleDropdown: () => {
		const dropdown = $(".dropdown"),
			dropdownToggle = $(".dropdown-toggle"),
			dropdownMenu = $(".dropdown-menu"),
			closeDropdown = $(".btn-apply-filter");
		dropdownToggle.on("click", function () {
			$(this).toggleClass("active");
			$(this).parent(dropdown).find(dropdownMenu).toggleClass("open");
		});
		// .parent(dropdown).on("mouseleave", function () {
		// 	$(this).parent(dropdown).find(dropdownToggle).removeClass("active");
		// 	$(this).parent(dropdown).find(dropdownMenu).removeClass("open");
		// });
		// dropdownToggle.on("mouseenter", function () {
		// 	$(this).addClass("active");
		// 	$(this).parent(dropdown).find(dropdownMenu).addClass("open");
		// });
		// dropdownMenu.on("mouseleave", function () {
		// 	$(this).parent(dropdown).find(dropdownToggle).removeClass("active");
		// 	$(this).parent(dropdown).find(dropdownMenu).removeClass("open");
		// });
		closeDropdown.on("click", function () {
			$(this).parents(dropdown).find(dropdownToggle).removeClass("active");
			$(this).parents(dropdown).find(dropdownMenu).removeClass("open");
		});
	},
	toggleMenu: () => {
		buttonMenu.on("click", function () {
			$(this).toggleClass("open");
			mobileWrap.toggleClass('open');
			backdrop.toggleClass('open');
		});
	},
	toggleSearch: () => {
		buttonSearch.on("click", function () {
			$(this).toggleClass("open");
			searchWrap.toggleClass("open");
		});
	},
	setBackground: () => {
		$("[setBackground]").each(function () {
			$(this).css(
				"background-image",
				"url(" + $(this).attr("setBackground") + ")"
			);
		});
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.customSelect();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleMenu();
		APP.toggleSearch();
		APP.setBackground();
	}
}

const homeBanner = new Swiper(".home-banner .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	grabCursor: true,
	effect: "creative",
	creativeEffect: {
		prev: {
			shadow: true,
			translate: ["-20%", 0, -1],
		},
		next: {
			translate: ["100%", 0, 0],
		},
	},
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
});

const homeCompany = new Swiper(".home-company .swiper", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	slidesPerView: 2,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-company .button-next",
		prevEl: ".home-company .button-prev",
	},
	pagination: {
		el: ".home-company .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 15,
		},
		1260: {
			slidesPerView: 5,
			spaceBetween: 15,
		},
		1440: {
			slidesPerView: 6,
			spaceBetween: 20,
		},
	},
});

const featuredJobsSlider = new Swiper(".swiper-featured-job", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	grabCursor: true,
	effect: "creative",
	creativeEffect: {
		prev: {
			shadow: true,
			translate: ["-20%", 0, -1],
		},
		next: {
			translate: ["100%", 0, 0],
		},
	},
	navigation: {
		nextEl: ".swiper-featured-job .button-next",
		prevEl: ".swiper-featured-job .button-prev",
	},
	pagination: {
		el: ".swiper-featured-job .swiper-pagination",
		clickable: true,
	},
});

const homeEmployersThumbs = new Swiper(".home-employers .swiper-thumbs", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	slidesPerView: 2,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	freeMode: true,
	watchSlidesProgress: true,
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 15,
		},
		1260: {
			slidesPerView: 5,
			spaceBetween: 15,
		},
		1440: {
			slidesPerView: 6,
			spaceBetween: 20,
		},
	},
});

const homeEmployers = new Swiper(".home-employers .swiper-top", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	spaceBetween: 10,
	navigation: {
		nextEl: ".home-employers .button-next",
		prevEl: ".home-employers .button-prev",
	},
	autoHeight: true,
	thumbs: {
		swiper: homeEmployersThumbs,
	},
});

const homeReview = new Swiper(".home-review .swiper", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	slidesPerView: 1,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-review .button-next",
		prevEl: ".home-review .button-prev",
	},
	pagination: {
		el: ".home-review .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 2,
			spaceBetween: 30,
		},
		1260: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
		1440: {
			slidesPerView: 3,
			spaceBetween: 75,
		},
	},
});

const homeNews = new Swiper(".home-news .swiper", {
	loop: true,
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	slidesPerView: 1,
	// spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-news .button-next",
		prevEl: ".home-news .button-prev",
	},
	pagination: {
		el: ".home-news .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		768: {
			slidesPerView: 2,
			// spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			// spaceBetween: 20,
		},
		1260: {
			slidesPerView: 4,
			// spaceBetween: 20,
		},
		1440: {
			slidesPerView: 4,
			// spaceBetween: 30,
		},
	},
});

const bannerChild = new Swiper(".banner-child .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 0,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	grabCursor: true,
	effect: "creative",
	creativeEffect: {
		prev: {
			shadow: true,
			translate: ["-20%", 0, -1],
		},
		next: {
			translate: ["100%", 0, 0],
		},
	},
	navigation: {
		nextEl: ".banner-child .button-next",
		prevEl: ".banner-child .button-prev",
	},
	pagination: {
		el: ".banner-child .swiper-pagination",
		clickable: true,
	},
});

const about2Slider = new Swiper(".about-2 .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	spaceBetween: 10,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".about-2 .button-next",
		prevEl: ".about-2 .button-prev",
	},
	pagination: {
		el: ".about-2 .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		1260: {
			direction: "vertical",
		}
	}
});

const featuredJobsSection = new Swiper(".featured-jobs-section .swiper", {
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	// grid: {
	// 	rows: 4,
	// },
	spaceBetween: 10,
	navigation: {
		nextEl: ".featured-jobs-section .button-next",
		prevEl: ".featured-jobs-section .button-prev",
	},
	pagination: {
		el: ".featured-jobs-section .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			// slidesPerView: 2,
			spaceBetween: 20,
		},
		1260: {
			// slidesPerView: 3,
			spaceBetween: 30,
		},
	}
});

const employersDetailBenefits = new Swiper(".employers-detail-section .company-benefits .swiper", {
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	grid: {
		rows: 2,
	},
	spaceBetween: 10,
	slidesPerView: 2,
	navigation: {
		nextEl: ".employers-detail-section .company-benefits .button-next",
		prevEl: ".employers-detail-section .company-benefits .button-prev",
	},
	pagination: {
		el: ".employers-detail-section .company-benefits .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 4,
			spaceBetween: 20,
		},
		1260: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
		1440: {
			slidesPerView: 5,
			spaceBetween: 60,
		},
	}
});

const employersDetailGallery = new Swiper(".employers-detail-section .gallery-list .swiper", {
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 10,
	navigation: {
		nextEl: ".employers-detail-section .gallery-list .button-next",
		prevEl: ".employers-detail-section .gallery-list .button-prev",
	},
	pagination: {
		el: ".employers-detail-section .gallery-list .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
			grid: {
				rows: 2,
			},
		},
		1260: {
			slidesPerView: 3,
			spaceBetween: 30,
			grid: {
				rows: 2,
			},
		},
	}
});

const newsOtherSlider = new Swiper(".news-detail-section .news-other-wrap .swiper", {
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 10,
	navigation: {
		nextEl: ".news-detail-section .news-other-wrap .button-next",
		prevEl: ".news-detail-section .news-other-wrap .button-prev",
	},
	pagination: {
		el: ".news-detail-section .news-other-wrap .swiper-pagination",
		clickable: true,
		dynamicBullets: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1260: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
	}
});

const modalChooseCVSlider = new Swiper(".modal-choose-cv .swiper", {
	// autoplay: {
	// 	delay: 3000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 10,
	navigation: {
		nextEl: ".modal-choose-cv .button-next",
		prevEl: ".modal-choose-cv .button-prev",
	},
	pagination: {
		el: ".modal-choose-cv .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		375: {
			slidesPerView: 1.25,
			spaceBetween: 10,
		},
		576: {
			slidesPerView: 1.75,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 2.25,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1260: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
	}
});

function lozadInit() {
	const observer = lozad('.lozad', {
		threshold: 0.1,
		// enableAutoReload: true,
	});
	observer.observe();
}

function fancyboxInit() {
	var defaultOptions = {
		// Options will go here
		protect: true,
		infobar: false,
		buttons: [
			"zoom",
			"share",
			"slideShow",
			"fullScreen",
			"download",
			"thumbs",
			"close",
		],
		// parentEl: "form",
		// Set `touch: false` to disable panning/swiping
		touch: false,

	};
	$('[data-fancybox]').fancybox({
		thumbs: {
			autoStart: true,
			axis: "x"
		},
		...defaultOptions,
	});
	// popup img in content
	// $().fancybox({
	// 	selector: '.full-content a:visible',
	// 	thumbs: {
	// 		autoStart: true,
	// 		axis: "x"
	// 	},
	// });
	// $.fancybox.open({
	// 	src: $('#pnlThankYou'),
	// 	opts: {
	// 		afterClose: function (instance, current) {
	// 			console.info( 'afterClose!' );
	// 			$('.form-group input').val('');
	// 			$('.form-group input').prop("checked", false);
	// 			$('.form-group select').val(null).trigger('change');
	// 		}
	// 	},
	// });
	// clear form after close
	$().fancybox({
		selector: '.register-success-modal',
		afterClose: function (instance, current) {
			$('.form-group input').val('');
			$('.form-group input').prop("checked", false);
			$('.form-group select').val(null).trigger('change');
		},
	});
	// Start new fancybox instance
	// $.fancybox.open( items, opts, index );

	// Get refrence to currently active fancybox instance
	// $.fancybox.getInstance();

	// Close currently active fancybox instance (pass `true` to close all instances)
	// $.fancybox.close();

	// Close all instances and unbind all events
	// $.fancybox.destroy();
}

function equalHeight(el) {
	var height = 0;
	$(el).each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			$(el).height(height)
		}, 100)
	})
}

function accordionInit() {
	$('.accordion-item').on('click', function () {
		$(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		$(this)
			.find('.accordion-header').toggleClass('active')
			.parents('.accordion-item')
			.siblings().find('.accordion-header').removeClass('active')
		$(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp('active')
	});
}

function revealPwd() {
	// Use event delegation to improve performance and reduce memory usage
	$(document).on("click", ".reveal-password", function () {
		$(this).parents('.form-password').toggleClass("show");
		$(this).parents('.form-password').find("input:not([type='hidden'])").attr("type", function (i, val) {
			return val === "text" ? "password" : "text";
		});
	});
}

function inputPasswordValidate(){
	// password rules validation
	var rulesHtml = `<ul class='field_rules'>
		<li data-check="one_lower_upper_num">${languageFE[langFE].one_lower_upper_num}</li>
		<li data-check="one_spec">${languageFE[langFE].one_special}</li>
		<li data-check="night_char_mini">${languageFE[langFE].at_least_8_characters}</li>
	</ul>`;

	$('.form-password-rules-validate').each(function (index) {
		// if (index == 0) {
		$(this).find('input[type=password]').on("focus", function () {
			var value = this.value;
			var pattern = new RegExp(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/);
			if (!pattern.test(value)) {
				$(this).closest('.form-password').append(rulesHtml);
				$(':password').on('input', onPasswordInput);
			}
		});

		$('input[type=password]').on("focusout", function () {
			$(this).closest('.form-password').find('.field_rules').remove();
		});
		// }
	});

	// Callbacks
	function onPasswordInput(e) {
		var $input = $(this),
			value = this.value;
		var $rulesItems = $input.closest('.form-password-rules-validate').find('.field_rules').find('li')
		var rules = {
			"one_lower_upper_num": /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])/,
			// "one_lower": /[a-z]/,
			// "one_upper": /[A-Z]/,
			// "one_num": /[0-9]/,
			"one_spec": /[^a-z0-9]/i,
			"night_char_mini": /.{8,}/
		};

		// mark the input field if it has any value
		this.classList.toggle('hasValue', this.value);

		// itterate all the rules and validate them
		$rulesItems.each((i, elm) => {
			var valid, ruleName = elm.getAttribute('data-check');

			if (rules[ruleName]) {
				valid = new RegExp(rules[ruleName]).test(value);
				elm.classList.toggle('pass', valid);
			}
		});
	}
}

function sliderInit() {
	// Salary Range Slider
	$('[data-slider]').each(function (index) {
		$(this).find('.range-slider').attr('id', 'range-slider-' + index);
		$(this).find('.range-slider-text').attr('id', 'range-slider-text-' + index);
		$(this).find('.snap-value-lower').attr('id', 'snap-value-lower-' + index);
		$(this).find('.snap-value-upper').attr('id', 'snap-value-upper-' + index);
		let rangeSlider = document.getElementById('range-slider-' + index);
		let rangeSliderValues = document.getElementById('range-slider-text-' + index);
		let snapValues = [
			document.getElementById('snap-value-lower-' + index),
			document.getElementById('snap-value-upper-' + index)
		];
		let dataMin = $(this).data('min');
		let dataMax = $(this).data('max');
		let dataStart = $(this).data('start');
		let dataEnd = $(this).data('end');
		let textRangeSliderValues = $(rangeSliderValues).html();
		noUiSlider.create(rangeSlider, {
			start: [
				parseFloat(dataStart),
				parseFloat(dataEnd)
			],
			range: {
				'min': [parseFloat(dataMin)],
				'max': [parseFloat(dataMax)]
			},
			format: wNumb({
				decimals: 0
			}),
			connect: true,
		});
		rangeSlider.noUiSlider.on('update', function (values, handle) {
			// snapValues[handle].innerHTML = values[handle];
			snapValues[handle].value = values[handle];
		});
		snapValues.forEach(function (input, handle) {
			input.addEventListener('change', function () {
				rangeSlider.noUiSlider.setHandle(handle, this.value);
			});
			input.addEventListener('keydown', function (e) {
				var values = rangeSlider.noUiSlider.get();
				var value = Number(values[handle]);

				// [[handle0_down, handle0_up], [handle1_down, handle1_up]]
				var steps = rangeSlider.noUiSlider.steps();

				// [down, up]
				var step = steps[handle];

				var position;

				// 13 is enter,
				// 38 is key up,
				// 40 is key down.
				switch (e.which) {

					case 13:
						rangeSlider.noUiSlider.setHandle(handle, this.value);
						break;

					case 38:

						// Get step to go increase slider value (up)
						position = step[1];

						// false = no step is set
						if (position === false) {
							position = 1;
						}

						// null = edge of slider
						if (position !== null) {
							rangeSlider.noUiSlider.setHandle(handle, value + position);
						}

						break;

					case 40:

						position = step[0];

						if (position === false) {
							position = 1;
						}

						if (position !== null) {
							rangeSlider.noUiSlider.setHandle(handle, value - position);
						}

						break;
				}
			});
		});
		$(this).find('.btn-apply-filter').on('click', function () {
			rangeSlider.noUiSlider.on('update', function (values) {
				rangeSliderValues.innerHTML = values.join(' - ') + ' triệu';
			});
		});
		$(this).find('.btn-remove-filter').on('click', function () {
			rangeSlider.noUiSlider.set([parseFloat(dataStart), parseFloat(dataEnd)]);
			rangeSliderValues.innerHTML = textRangeSliderValues;
			$('.dropdown-menu').removeClass('open');
		});
	})
}

function onlicvMenuInit() {
	$(".onlicv-menu .btn-toggle-jobs-filter").on('click', function () {
		$(".jobs-filter-bar").toggleClass("open");
		body.toggleClass("overflow-hidden");
		backdrop.toggleClass("open");
	});
	$(".onlicv-menu .btn-toggle-job-sorting").on('click', function () {
		$(".job-sorting").toggleClass("open");
		body.toggleClass("overflow-hidden");
		backdrop.toggleClass("open");
	});
}

function jobsFilterInit() {
	// $('.jobs-filter-bar .btn-reset-filter').on('click', function () {
	// 	$('.jobs-filter-bar .custom-select-single select').val(null).trigger("change");
	// 	$('.jobs-filter-bar .custom-select-multiple select').val(null).trigger("change");
	// });
	$('.jobs-filter-bar .btn-close-filter').on('click', function () {
		$('.jobs-filter-bar').removeClass('open');
		backdrop.removeClass('open');
		body.removeClass('overflow-hidden');
		$('.job-search-wrap .form-group.form-submit input[type="submit"]').trigger('click');
	});
}

function toggleDescription() {
	$('.job-detail-item .job-detail-content').each(function () {
		if ($(this).height() >= 310) {
			$(this).css("height", "310px");
			$(this).parents(".job-detail-item").find(".btn-toggle-description").css("display", "flex");
		}
	});
	$(".btn-toggle-description").on('click', function () {
		$(this).parents(".job-detail-item").find(".job-detail-content").css("height", "auto");
		$(this).hide();
	});
}

function toggleFaqsCategories() {
	$('.faqs-nav-mobile').on('click', function () {
		$(this).toggleClass('active');
		$('.faqs-nav').slideToggle();
	});
}

function countUpInit() {
	let targetItem = $('.countup-item');
	const options = {
		separator: '.',
		enableScrollSpy: true, // if true -> turn off countUp.start() after first scroll spy check
		scrollSpyDelay: 500, // delay for scroll spy check
		scrollSpyOnce: true,
	};
	if (targetItem) {
		targetItem.each(function (index) {
			let endVal = $(targetItem[index]).data('count');
			let countUp = new CountUp(targetItem[index], endVal, options);
			if (!countUp.error) {
			} else {
				console.error(countUp.error);
			}
		});
	}
	// let demo = new CountUp(targetItem, endVal, { enableScrollSpy: true });
}

function uploadInit() {
	$(".upload-file").each(function (index) {
		const $this = $(this);
		var readURL = function (input) {
			if (input.files && input.files[index]) {
				var reader = new FileReader();
				reader.onload = function (e) {
					$this.parents('.upload-avatar').find('.profile-pic img').attr('src', e.target.result);
				}
				reader.readAsDataURL(input.files[index]);
			}
		}
		$(this).on('change', function () {
			let fileName = $(this).val().split('\\').pop();
			readURL(this);
			console.log("🚀 ~ file: main.js:877 ~ fileName:", fileName)
		});
		$(".profile-pic").on('click', function () {
			$(this).parents('.upload-avatar').find('.upload-file').trigger('click');
		});
	});
}

function CKEditorInit() {
	const optionsEditor = {
		// https://ckeditor.com/docs/ckeditor5/latest/features/toolbar/toolbar.html#extended-toolbar-configuration-format
		toolbar: {
			items: [
				'heading', '|',
				'bold', 'italic', 'underline', '|',
				'alignment', '|',
				'fontSize', 'fontColor', '|',
				'bulletedList', 'numberedList', '|',//'todoList',
				'link', 'insertImage', 'blockQuote', 'insertTable', '|',
				'exportPDF',
				// 'fontFamily', 'findAndReplace', 'selectAll', 'strikethrough', 'subscript', 'superscript', 'removeFormat', 'fontBackgroundColor', 'highlight', 'code', 'sourceEditing', '|',
				// 'outdent', 'indent', '|',
				// 'undo', 'redo',
				// '-',
				// 'htmlEmbed', 'mediaEmbed', 'codeBlock','|',
				// 'specialCharacters', 'horizontalLine', 'pageBreak', '|',
				// 'textPartLanguage', 'exportWord', '|',
			],
			shouldNotGroupWhenFull: true
		},
		// Changing the language of the interface requires loading the language file using the <script> tag.
		// language: 'es',
		list: {
			properties: {
				styles: true,
				startIndex: true,
				reversed: true
			}
		},
		// https://ckeditor.com/docs/ckeditor5/latest/features/headings.html#configuration
		heading: {
			options: [
				{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
				{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
				{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
				{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
				{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
				{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
				{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
			]
		},
		// https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-family-feature
		fontFamily: {
			options: [
				'default',
				'Arial, Helvetica, sans-serif',
				'Courier New, Courier, monospace',
				'Georgia, serif',
				'Lucida Sans Unicode, Lucida Grande, sans-serif',
				'Tahoma, Geneva, sans-serif',
				'Times New Roman, Times, serif',
				'Trebuchet MS, Helvetica, sans-serif',
				'Verdana, Geneva, sans-serif',
			],
			supportAllValues: true
		},
		// https://ckeditor.com/docs/ckeditor5/latest/features/font.html#configuring-the-font-size-feature
		fontSize: {
			options: [10, 12, 14, 'default', 18, 20, 22],
			supportAllValues: true
		},
		// Be careful with the setting below. It instructs CKEditor to accept ALL HTML markup.
		// https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html#enabling-all-html-features
		htmlSupport: {
			allow: [
				{
					name: /.*/,
					attributes: true,
					classes: true,
					styles: true
				}
			]
		},
		// Be careful with enabling previews
		// https://ckeditor.com/docs/ckeditor5/latest/features/html-embed.html#content-previews
		htmlEmbed: {
			showPreviews: true
		},
		// https://ckeditor.com/docs/ckeditor5/latest/features/link.html#custom-link-attributes-decorators
		link: {
			decorators: {
				addTargetToExternalLinks: true,
				defaultProtocol: 'https://',
				toggleDownloadable: {
					mode: 'manual',
					label: 'Downloadable',
					attributes: {
						download: 'file'
					}
				}
			}
		},
		// https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html#configuration
		mention: {
			feeds: [
				{
					marker: '@',
					feed: [
						'@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
						'@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
						'@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
						'@sugar', '@sweet', '@topping', '@wafer'
					],
					minimumCharacters: 1
				}
			]
		},
		// The "super-build" contains more premium features that require additional configuration, disable them below.
		// Do not turn them on unless you read the documentation and know how to configure them and setup the editor.
		removePlugins: [
			// These two are commercial, but you can try them out without registering to a trial.
			// 'ExportPdf',
			// 'ExportWord',
			'CKBox',
			'CKFinder',
			'EasyImage',
			// This sample uses the Base64UploadAdapter to handle image uploads as it requires no configuration.
			// https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/base64-upload-adapter.html
			// Storing images as Base64 is usually a very bad idea.
			// Replace it on production website with other solutions:
			// https://ckeditor.com/docs/ckeditor5/latest/features/images/image-upload/image-upload.html
			// 'Base64UploadAdapter',
			'RealTimeCollaborativeComments',
			'RealTimeCollaborativeTrackChanges',
			'RealTimeCollaborativeRevisionHistory',
			'PresenceList',
			'Comments',
			'TrackChanges',
			'TrackChangesData',
			'RevisionHistory',
			'Pagination',
			'WProofreader',
			// Careful, with the Mathtype plugin CKEditor will not load when loading this sample
			// from a local file system (file://) - load this site via HTTP server if you enable MathType
			'MathType'
		],
		updateSourceElementOnDestroy: true
	}
	$('.box-profile').each(function (index) {
		$(this).find('.form-editor textarea').attr('id', `editor-${index}`)
		$(this).find('.btn-editor-save').attr('id', `btn-editor-save-${index}`)
		$(this).find('.btn-editor-cancel').attr('id', `btn-editor-cancel-${index}`)
		$(this).find('.result-content').attr('id', `result-content-${index}`)
		let editorElm = document.getElementById(`editor-${index}`);
		let dataPlaceholder = $(`#editor-${index}`).data('placeholder');
		let isDirty = false;
		let HTTP_SERVER_LAG = 500;
		CKEDITOR.ClassicEditor.create(editorElm, {
			// https://ckeditor.com/docs/ckeditor5/latest/features/editor-placeholder.html#using-the-editor-configuration
			placeholder: dataPlaceholder,
			...optionsEditor,
		}).then(editor => {
			window.editor = editor;
			handleStatusChanges(editor);
			handleSaveButton(editor);
			handleBeforeunload(editor);
		}).catch(error => {
			console.error(error);
		});
		// Handle clicking the "Save" button by sending the data to a
		// fake HTTP server (emulated here with setTimeout()).
		function handleSaveButton(editor) {
			const saveButton = document.querySelector(`#btn-editor-save-${index}`);
			const pendingActions = editor.plugins.get('PendingActions');
			saveButton.addEventListener('click', evt => {
				const data = editor.getData();
				// Register the action of saving the data as a "pending action".
				// All asynchronous actions related to the editor are tracked like this,
				// so later on you only need to check `pendingActions.hasAny` to check
				// whether the editor is busy or not.
				const action = pendingActions.add('Saving changes');
				evt.preventDefault();
				// Save the data to a fake HTTP server.
				setTimeout(() => {
					pendingActions.remove(action);
					// Reset isDirty only if the data did not change in the meantime.
					if (data == editor.getData()) {
						isDirty = false;
					}
					updateStatus(editor);
					updateContent(editor);
				}, HTTP_SERVER_LAG);
			});
		}
		// Listen to new changes (to enable the "Save" button) and to
		// pending actions (to show the spinner animation when the editor is busy).
		function handleStatusChanges(editor) {
			editor.plugins.get('PendingActions').on('change:hasAny', () => updateStatus(editor));
			editor.model.document.on('change:data', () => {
				isDirty = true;
				updateStatus(editor);
			});
		}

		// If the user tries to leave the page before the data is saved, ask
		// them whether they are sure they want to proceed.
		function handleBeforeunload(editor) {
			const pendingActions = editor.plugins.get('PendingActions');
			window.addEventListener('beforeunload', evt => {
				if (pendingActions.hasAny) {
					evt.preventDefault();
				}
			});
		}

		function updateStatus(editor) {
			const saveButton = document.querySelector(`#btn-editor-save-${index}`);
			// Disables the "Save" button when the data on the server is up to date.
			if (isDirty) {
				saveButton.classList.add('active');
			} else {
				saveButton.classList.remove('active');
			}
			// Shows the spinner animation.
			if (editor.plugins.get('PendingActions').hasAny) {
				saveButton.classList.add('saving');
			} else {
				saveButton.classList.remove('saving');
			}
		}

		function updateContent(editor) {
			let data = editor.getData();
			let content = document.querySelector(`#result-content-${index}`);
			let editorTextarea = document.querySelector(`#editor-${index}`);
			content.innerHTML = data;
			editorTextarea.value = data;
			if (isDirty) {
				content.classList.add('hide');
			} else {
				content.classList.remove('hide');
			}
			// Shows the spinner animation.
			if (editor.plugins.get('PendingActions').hasAny) {
				content.classList.add('loading');
			} else {
				content.classList.remove('loading');
			}
		}
	})
}

// Helper function to convert a data URI to a Blob object
function dataURItoBlob(dataURI) {
	const byteString = atob(dataURI.split(',')[1]);
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
}

function filepondInit() {
	FilePond.registerPlugin(
		FilePondPluginFileEncode,
		FilePondPluginFileMetadata,
		FilePondPluginFileValidateSize,
		FilePondPluginFileValidateType,
		FilePondPluginImagePreview,
	);

	$('.upload-single').each(function(index){
		$(this).addClass('upload-single-'+index);
		$(this).parents('.form-upload').find('.upload-single-value').addClass('upload-single-value-'+index);
		const inputSingleElm = document.querySelector('.upload-single-'+index);
		var outputArray = [];
		const filepondSingleOutput = document.querySelector('.upload-single-value-'+index);

		if (filepondSingleOutput.value) {
			outputArray = JSON.parse(filepondSingleOutput.value);
		}

		const pondSingle = FilePond.create(inputSingleElm, {
			credits: false,
			maxFileSize: '5MB',
			allowFileEncode: true,
			allowFileMetadata: true,
			styleButtonRemoveItemPosition: 'right',
			// fileMetadataObject: outputArray,
			onaddfile(err, item) {
				if (err) {
					console.warn(err);
					return;
				}
				const dataURL = item.getFileEncodeDataURL();
				const fileMetadata = [{ 'name': item.file.name, 'data': dataURL }];
				filepondSingleOutput.value = JSON.stringify(fileMetadata);
			},
			onremovefile(err, file) {
				if (!err) {
					filepondSingleOutput.value = '';
				}
			},
		});

		if (filepondSingleOutput.value){
			// Get the base64-encoded image data
			const imageData = outputArray[0].data;
			// Set the file name
			const fileName = outputArray[0].name;
			// Convert the base64-encoded string to a Blob object
			const blob = dataURItoBlob(imageData);
			// Create a new File object from the Blob
			const file = new File([blob], fileName, { type: 'image/png' });
			// Add the file to FilePond
			pondSingle.addFile(file);
		}
	})

	$('.upload-multiple').each(function(index){
		$(this).addClass('upload-multiple-' + index);
		$(this).parents('.form-upload').find('.upload-multiple-value').addClass('upload-multiple-value-' + index);
		const inputMultipleElm = document.querySelector('.upload-multiple-' + index);
		const filepondMultipleOutput = document.querySelector('.upload-multiple-value-' + index);
		var multipleArray = [];
		const pondMultiple = FilePond.create(inputMultipleElm, {
			allowMultiple: true,
			allowReorder: true,
			allowFileEncode: true,
			allowFilePoster: false,
			allowImageEditor: false,
			credits: false,
			maxFiles: 10,
			maxFileSize: '5MB',
			styleButtonRemoveItemPosition: 'right',
			onaddfile(err, fileItem) {
				if (err) {
					console.warn(err);
					return;
				}
				const dataURL = fileItem.getFileEncodeDataURL();
				const fileMetadata = { 'id': fileItem.id, 'name': fileItem.file.name, 'data': dataURL };
				multipleArray.push(fileMetadata);
				filepondMultipleOutput.value = JSON.stringify(multipleArray);
			},
			onremovefile(err, fileItem) {
				if (!err) {
					multipleArray = multipleArray.filter((item) => {
						return item.id !== fileItem.id;
					})
					if (multipleArray.length != 0){
						filepondMultipleOutput.value = JSON.stringify(multipleArray);
					}else{
						filepondMultipleOutput.value = '';
					}
				}
			},
		});
		if (filepondMultipleOutput.value) {
			const outputArray = JSON.parse(filepondMultipleOutput.value);
			outputArray.forEach((item) => {
				// Get the base64-encoded image data
				let imageData = item.data;
				// Set the file name
				let fileName = item.name;
				// Convert the base64-encoded string to a Blob object
				const blob = dataURItoBlob(imageData);
				// Create a new File object from the Blob
				const file = new File([blob], fileName, { type: 'image/png' });
				// Add the file to FilePond
				pondMultiple.addFile(file);
				// pondMultiple.addFile(item.data)
			});
		}
	});
}

var PROFILE = {
	toggleUserMenu: () => {
		$(".user-menu-mobile").on('click', function () {
			$(this).toggleClass("active");
			$(".user-profile-box").toggleClass("open");
		});
		$(".user-profile-box .has-children > a").on('click', function (e) {
			e.preventDefault();
			$(this).next().slideToggle();
			$(this).parents(".has-children").siblings().find('.sub-menu').slideUp();
		});
	},
	init: () => {
		PROFILE.toggleUserMenu();
	}
}

var EMPLOYER = {
	toggleEmployerMenu: () => {
		$(".employer-menu-mobile").on('click', function () {
			$(this).toggleClass("active");
			$("#sidebar").toggleClass("open");
			$("body").toggleClass("overflow-hidden");
		});
	},
	init: () => {
		EMPLOYER.toggleEmployerMenu();
	}
}
